import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  FormikControl,
  LocationsDropdownWrapper,
  SubmitBtn,
  FormFailed,
  FormSubmitting,
} from '..';
import { onSubmitClaim } from './utils/onSubmitClaim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const claimSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
  location: Yup.string().required('Required'),
});

const Success = ({ coupon }) => (
  <div className='text-center pt-4'>
    <p className='p-subhead mb-3'>
      You’ve successfully claimed your{' '}
      <strong>
        <span className='milan-text-primary'>
          {coupon.fetchedData.discount}%
        </span>
      </strong>
      &nbsp;discount.
    </p>
    <p className='px-xl-5'>
      You should receive an email from us shortly confirming your discount
      through{' '}
      <strong>
        {new Date(coupon.fetchedData.expDate.toDate()).toString().slice(0, 15)}
      </strong>
    </p>
  </div>
);

export function ClaimDiscount({ siteData, coupon, type }) {
  return (
    <Formik
      // initialValues={{name: ''}}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phoneMask: '',
        location: '',
      }}
      validationSchema={claimSchema}
      onSubmit={(user, props) =>
        onSubmitClaim(user, props, coupon, siteData.selectedStore, type)
      }
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form className='w-100 card p-3 milan-bg-white mb-5'>
            {formik.isSubmitting ? (
              <FormSubmitting />
            ) : formik.status === 'Success' ? (
              <Success coupon={coupon} />
            ) : formik.status === 'Failed' ? (
              <FormFailed />
            ) : (
              <>
                <h5 className='mb-4 text-capitalize milan-text-primary'>
                  Claim Your Discount
                </h5>
                {/* <div className='mb-4 text-start'>
                  <FormikControl control='emailInput' name='email' placeholder='youremail@mailbox.com' />
                </div> */}
                <div className='row mb-4 text-start'>
                  <div className='col-12 col-lg-6 mb-4 mb-lg-0'>
                    <FormikControl
                      control='input'
                      name='firstName'
                      placeholder='First Name*'
                      type='text'
                    />
                  </div>
                  <div className='col-12 col-lg-6'>
                    <FormikControl
                      control='input'
                      name='lastName'
                      placeholder='Last Name*'
                      type='text'
                    />
                  </div>
                </div>
                <div className='mb-4 text-start'>
                  <FormikControl
                    control='emailInput'
                    placeholder='Your Email*'
                    name='email'
                  />
                </div>
                <div className='mb-4 text-start'>
                  <FormikControl
                    control='phoneInput'
                    placeholder='Phone Number*'
                    name='phoneMask'
                    type='tel'
                  />
                </div>
                <div className='mb-4 text-start'>
                  <label htmlFor='location'>
                    Location{' '}
                    <sup>
                      <FontAwesomeIcon icon={faAsterisk} />
                    </sup>
                  </label>
                  <LocationsDropdownWrapper
                    {...{ siteData, formik }}
                    fieldName='location'
                  />
                </div>
                <SubmitBtn formik={formik} />
                <p className='mb-0 mt-3 milan-fp mx-auto'>
                  I consent by electronic signature to be contacted by Milan
                  Laser by live agent, email & automatic telephone dialer for
                  information, offers or advertisements via email/ phone call/
                  text message at the number & email provided. No purchase
                  necessary.
                </p>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ClaimDiscount;
