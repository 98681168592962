import { DOWNLOAD_PATH } from '../../../utils/constants';

const PRESS_PATH = DOWNLOAD_PATH + '/press/press-releases/';

export const pressReleasesList = [
  {
    title:
      'Milan Laser Hair Removal Bares Its Brand at Miami Swim Week® The Shows',
    source: PRESS_PATH + 'Press_Release_Milan_Laser_Miami_Swim_Week.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Announces New Chief Operating Officer',
    source:
      PRESS_PATH +
      'press-release-milan-laser-hair-removal-announces-new-COO.pdf',
  },
  {
    title:
      'Jared Widseth of Milan Laser Hair Removal Honored with OnCon Icon Corporate Counsel Award',
    source:
      PRESS_PATH +
      'Jared-Widseth-of-Milan-Laser-Hair-Removal-Honored-with-OnCon-Icon-Corporate-Counsel-Award.pdf',
  },
  {
    title:
      'Baird Holm LLP Names Milan Laser Hair Removal One of the Best Places to Work in Omaha®',
    source:
      PRESS_PATH +
      'Baird-Holm-LLP-Names-Milan-Laser-Hair-Removal One-of-the-Best-Places-to-Work-in-Omaha.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Announces New Chief Marketing Officer',
    source:
      PRESS_PATH +
      'Milan-Laser-Hair-Removal-Announces-New-Chief-Marketing-Officer.pdf',
  },
  {
    title:
      "Milan Laser Ranks No. 1125 on the 2023 Inc. 5000 List of America's Fastest-Growing Private Companies",
    source:
      PRESS_PATH +
      'Milan-Laser-Ranks-No.-1125-on-the-2023-Inc.-5000-List-of-America_s-Fastest-Growing-Private-Companies.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Opens 300th Location',
    source: PRESS_PATH + 'Milan-Laser-Hair-Removal-Opens-300th-Location.pdf',
  },
  {
    title:
      'EY Announces Dr. Shikhar Saxena of Milan Laser Hair Removal as an Entrepreneur of the...',
    source:
      PRESS_PATH +
      'EY-Announces-Dr.-Shikhar-Saxena-of-Milan-Laser-Hair-Removal-as-an-Entrepreneur-of-the-Year-2023-Heartland-Award-Winner.pdf',
  },
  {
    title:
      'Milan Laser continues Growth with New Strategic Investment from Sixth Street and Wildcat Captial...',
    source:
      PRESS_PATH +
      'Milan-Laser-continues-Growth-with-New-Strategic-Investment-from-Sixth-Street-and-Wildcat-Capital-Management-in-Partnership-with-LGP.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Announces New Chief Development Officer',
    source:
      PRESS_PATH +
      'Milan-Laser-Hair-Removal-Announces-New-Chief-Development-Officer.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Announces New Company President',
    source:
      PRESS_PATH +
      'Milan-Laser-Hair-Removal-Announces-New-Company-President.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Celebrates 10th Anniversary',
    source: DOWNLOAD_PATH + '/press/press-releases/Milan_Laser_10_year.pdf',
  },
  {
    title:
      'Milan Laser Hair Removal Announces New National Headquarters in Omaha, Nebraska',
    source: DOWNLOAD_PATH + '/press/press-releases/Milan_Laser_New_HQ.pdf',
  },
  {
    title: 'Milan Laser Hair Removal Donation Creates New Tech Lab',
    source:
      DOWNLOAD_PATH + '/press/press-releases/NelsonMandelaElementarySchool.pdf',
  },
  {
    title: "Nation's Largest Laser Hair Removal Company",
    source:
      'https://www.prnewswire.com/news-releases/milan-laser-hair-removal-becomes-nations-largest-laser-hair-removal-company-301466711.html?tc=eml_cleartime',
  },
  {
    title: 'Going Big in Texas',
    source:
      'https://www.prnewswire.com/news-releases/going-big-in-texas---milan-laser-opens-its-100th-location-301177841.html',
  },
  {
    title: 'Ally Lending expands relationship with Milan Laser',
    source:
      'https://globalfintechseries.com/news/ally-lending-expands-relationship-with-milan-laser/',
  },
  {
    title: 'Milan Laser is coming to Nashville',
    source:
      'https://www.prnewswire.com/news-releases/milan-laser-hair-removal-now-open-in-the-nashville-metro-area-300907246.html',
  },
  {
    title: 'Milan Laser Hair Removal Unveils Newly Remodeled Flagship Location',
    source:
      'https://strictlybusinessomaha.com/news/business/milan-laser-hair-removal-unveils-newly-remodeled-flagship-location/',
  },
  {
    title:
      'Milan Laser now open in Aurora marking its sixth location in central Colorado',
    source:
      'https://www.prnewswire.com/news-releases/milan-laser-hair-removal-opens-new-aurora-location-300888170.html?tc=eml_cleartime',
  },
  {
    title: 'Milan Laser Hair Removal is coming to St. Louis',
    source:
      'https://www.prnewswire.com/news-releases/milan-laser-hair-removal-is-coming-to-st-louis-300888713.html?tc=eml_cleartime',
  },
  {
    title:
      'Now open in Albany – Visit Milan Laser at one of its seven locations in upstate NY',
    source: DOWNLOAD_PATH + '/press/press-releases/albany.pdf',
  },
  {
    title:
      'Milan Laser Hair Removal Opens there 55th location in Wheatridge Colorado',
    source: DOWNLOAD_PATH + '/press/press-releases/wheat-ridge.pdf',
  },
  {
    title: 'Milan Laser Hair Removal now open in Yorkdale Shoppes in Edina',
    source: DOWNLOAD_PATH + '/press/press-releases/edina.pdf',
  },
];
