import axios from 'axios';
import qs from 'qs';
import {
  COUPON_FORM_HANDLER,
  CORPORATE_SITE,
  GATSBY_DOMAIN,
  MILAN_CORS,
  hardTrim,
} from '../../../utils';

export const onSubmitClaim = (
  formValues,
  props,
  coupon,
  selectedStore,
  type
) => {
  axios({
    method: 'POST',
    url: COUPON_FORM_HANDLER,
    data: qs.stringify({
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      email: formValues.email,
      phone: `+${hardTrim(formValues.phoneMask)}`,
      // '00N1L00000F9eBV': selectedStore.store.salesforceValue,
      '00N1L00000F9eBV': formValues.location,
      oid: process.env.GATSBY_OID,
      lead_source: 'Coupon',
      description: `Claim Discount | CouponCode: ${coupon.fetchedData.couponCode.toUpperCase()} | Discount: ${
        coupon.fetchedData.discount
      }%`,
      retUrl: CORPORATE_SITE + '/coupon',
      Campaign_ID: type === 'offer' ? '7015d000002n11h' : '7015d0000037fvS',
      cloudamp__data__c: localStorage.getItem('cloudamp__data__c'),
    }),
    config: {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  })
    .then(
      (res) => console.log(res.status, 'lead created'),
      axios
        .post(
          `${MILAN_CORS}${GATSBY_DOMAIN}/coupon/coupon.php`,
          {
            email: formValues.email,
            code: coupon.fetchedData.couponCode,
            discount: coupon.fetchedData.discount,
            date: new Date(coupon.fetchedData.expDate.toDate())
              .toString()
              .slice(0, 15),
          },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Cache-Control': 'no-cache',
            },
          }
        )
        .then(
          (res) => console.log(res),
          props.setSubmitting(false),
          props.setStatus('Success')
        )
        .catch((err) => console.error(err))
    )
    .catch((err) => {
      console.error(err);
      props.setSubmitting(false);
      props.setStatus('Failed');
    });
};
