import React from 'react';
import { Link } from 'gatsby';
import GRAPHIC from '../../../images/lp/social/Aug24Shavings-The-Pit_s.svg';

function SocialHeroContent() {
  return (
    <div id='social-hero-content' className='container'>
      <div className='row'>
        <div className='offset-xs-1 col-md-6 offset-md-6'>
          <figure className='d-none d-md-block pb-4 text-center'>
            <img
              id='graphic'
              src={GRAPHIC}
              alt='Milan Laser Social'
              style={{ width: '78.65%' }}
            />
          </figure>
          <p className='pt-3 pt-md-none'>
            Ready to pretty up your pits? This month, get one FREE treatment and
            win 30, 40, or 50% OFF unlimited laser hair removal treatments for
            life! Our{' '}
            <Link to='/specials/#guarantee' className='link-color'>
              exclusive Unlimited Package™
            </Link>{' '}
            comes with every purchase and includes FREE touch-ups, so you’re
            covered for life at any of our 350+ locations. Make a pit stop at
            your{' '}
            <Link to='/locations' className='link-color'>
              local Milan Laser
            </Link>{' '}
            by <strong>August 31</strong> to get your Scratch & Save card and
            reveal your discount!
          </p>
          <div className='mt-4 text-center'>
            <Link className='milan-btn milan-cta-btn w-auto' to='#consult'>
              Book My Free Consult
            </Link>
          </div>
          <div className='mt-4 text-center '>
            <p className='mb-0 milan-fp pb-milan-50 pb-md-0'>
              <span>
                <span className='d-md-none'>
                  *Free treatment excludes body areas that are currently or have
                  been previously treated.
                </span>
                <span className='d-none d-md-inline'>
                  *Eligible body areas for free treatment include underarms,
                  bikini line, lower legs, face & neck, shoulders, or neckline.
                  Excludes previously treated areas.
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialHeroContent;
